import React, { useEffect, useState, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import {
  FaPlay,
  FaPause,
  FaBackwardStep,
  FaForwardStep,
  FaCircle,
  FaCircleDot,
} from 'react-icons/fa6'

export default function Slider(props) {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [direction, setDirection] = useState(1)
  const [isIconClick, setIsIconClick] = useState(false)
  const [autoPlay, setAutoPlay] = useState(true)
  const slides = [
    {
      id: 0,
      content: (
        <div className="teona">
          <div className="slogan">{t('slogan')}</div>
        </div>
      ),
    },
    // {
    //   id: 1,
    //   content: (
    //     <div className="slider-full-opacity">
    //       <div className="video-container">
    //         <video src="./video/1.mp4" controls autoPlay muted preload="auto" />
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   id: 2,
    //   content: (
    //     <div className="slider-full-opacity">
    //       <div className="video-container">
    //         <video src="./video/2.mp4" controls autoPlay muted />
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   id: 3,
    //   content: (
    //     <div className="slider-full-opacity">
    //       <div className="video-container">
    //         <video src="./video/3.mp4" controls autoPlay muted />
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   id: 4,
    //   content: (
    //     <div className="slider-full-opacity">
    //       <div className="video-container">
    //         <video src="./video/4.mp4" controls autoPlay muted />
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   id: 5,
    //   content: (
    //     <div className="slider-full-opacity">
    //       <div className="video-container">
    //         <video src="./video/5.mp4" controls autoPlay muted />
    //       </div>
    //     </div>
    //   ),
    // },
  ]

  const nextSlide = useCallback(() => {
    setDirection(1)
    setIsIconClick(false)
    setCurrentSlide((currentSlide + 1) % slides.length)
  }, [currentSlide, slides.length])

  const prevSlide = () => {
    setDirection(-1)
    setIsIconClick(false)
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length)
  }

  const selectSlide = useCallback((id) => {
    setCurrentSlide(id)
    setDirection(0)
    setIsIconClick(true)
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
  })

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay)
  }
  useEffect(() => {
    let slideInterval

    if (autoPlay) {
      slideInterval = setInterval(
        () => selectSlide((currentSlide + 1) % slides.length),
        10000
      )
    }

    return () => {
      clearInterval(slideInterval)
    }
  }, [currentSlide, selectSlide, slides.length, autoPlay])

  return (
    <>
      <div className="presentation">
        <AnimatePresence initial={false}>
          <motion.div
            key={currentSlide}
            initial={{ x: 1000 * direction, opacity: isIconClick ? 0 : 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 0, opacity: 1 }}
            transition={{ ease: 'linear', duration: 0.5 }}
            {...handlers}
          >
            {slides[currentSlide].content}
          </motion.div>
        </AnimatePresence>
      </div>
      {slides.length > 1 && (
        <span className="slider-nav">
          <span
            className={`slider-nav-item ${autoPlay ? 'active' : ''}`}
            onClick={() => toggleAutoPlay()}
          >
            {autoPlay ? <FaPause /> : <FaPlay />}
          </span>
          <span className="slider-nav-item" onClick={() => prevSlide()}>
            <FaBackwardStep />
          </span>
          {slides.map((el) => (
            <span
              key={el.id}
              className={`slider-nav-item ${
                currentSlide !== el.id ? 'active' : ''
              }`}
              onClick={() => selectSlide(el.id)}
            >
              {currentSlide === el.id ? <FaCircle /> : <FaCircleDot />}
            </span>
          ))}
          <span className="slider-nav-item" onClick={() => nextSlide()}>
            <FaForwardStep />
          </span>
        </span>
      )}
    </>
  )
}
