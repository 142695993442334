import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTelegram } from 'react-icons/fa6'
import { FaWhatsapp } from 'react-icons/fa6'
import { FaVk } from 'react-icons/fa6'

const phoneReplace = (phone) => {
  return '+' + phone.replace(/\D/g, '')
}

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer>
      <div id="contacts">
        <a href={'tel:' + phoneReplace(t('phonenum'))}>
          {t('phone')}: {t('phonenum')}
        </a>
        <br />
        <a href={'mailto:' + t('emailaddr')}>
          {t('email')}: {t('emailaddr')}
        </a>
        <br />
        &copy;2020-{new Date().getFullYear()}&nbsp;
        {t('allrights')}
        <br />
        <a
          href={'https://t.me/' + phoneReplace(t('phonenum'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram className="icon" />
        </a>
        <a
          href={'https://wa.me/' + phoneReplace(t('phonenum'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="icon" />
        </a>
        <a
          href={'https://vk.com/' + t('vk')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaVk className="icon" />
        </a>
      </div>
    </footer>
  )
}
