import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import './i18n'
import Loading from 'react-fullscreen-loading'

function Root() {
  return (
    <React.StrictMode>
      <Suspense
        fallback={
          <Loading loading={true} background="white" loaderColor="black" />
        }
      >
        <App />
      </Suspense>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Root />)
