import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { FaCircleXmark } from 'react-icons/fa6'
import Order from './Order'

const showOrders = (props, t) => {
  let summa = 0
  props.orders.forEach(
    (el) => (summa += Number.parseFloat(t('p_' + el.id) * el.quantity))
  )
  return (
    <div>
      {props.orders.map((el) => (
        <Order
          onDelete={props.onDelete}
          increaseQuantity={props.increaseQuantity}
          decreaseQuantity={props.decreaseQuantity}
          key={el.id}
          item={el}
        />
      ))}

      <span className="summa">
        {t('total')}: {new Intl.NumberFormat().format(summa)}
        {t('rub')}
        <div className="add-to-cart-full" onClick={() => props.onShowFinal()}>
          {t('buy')}
        </div>
      </span>
    </div>
  )
}

const showNothing = (t) => {
  return (
    <div className="empty">
      <motion.h2
        animate={{ x: [0, 10, 0] }}
        transition={{ duration: 0.5, repeat: 1 }}
      >
        {t('cart_empty')}
      </motion.h2>
    </div>
  )
}

const Cart = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        props.onShowCart(props.item)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <div>
      <motion.div
        animate={{ y: [-800, 0] }}
        exit={{ y: [0, -800] }}
        transition={{ ease: 'easeInOut' }}
        className="shop-cart"
      >
        <h2>{t('goods')}:</h2>
        <br />
        <FaCircleXmark
          className="close-button"
          onClick={() => props.onShowCart(props.item)}
        />
        {props.orders.length > 0 ? showOrders(props, t) : showNothing(t)}
      </motion.div>
    </div>
  )
}

export default Cart
