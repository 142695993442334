import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { withTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser'
import { FaCircleXmark } from 'react-icons/fa6'

export class ShowFinal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      finalOrder: '',
      name: '',
      email: '',
      phone: '',
      comment: '',
      emailSent: false,
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event) => {
    const { t } = this.props

    event.preventDefault()

    const subject = 'ORDER FROM TEONAPERFUME.RU'

    const body =
      'Клиент: ' +
      this.state.name +
      '\nНомер телефона: ' +
      this.state.phone +
      '\nАдрес почты: ' +
      this.state.email +
      '\n\n' +
      'Содержание заказа:\n' +
      this.state.finalOrder +
      '\n\n' +
      'Комментарий:\n' +
      this.state.comment

    var templateParams = {
      email: t('emailaddr'),
      message: body,
    }

    emailjs
      .send(
        'service_yrjpb9r',
        'template_2rq9x1o',
        templateParams,
        'ar6Bwk9NcqF6NJHfi'
      )
      .then(
        (response) => {
          this.setState({ emailSent: true })
          console.log('SUCCESS!', response.status, response.text)
        },
        (error) => {
          console.log('FAILED...', error)
          window.open(
            'mailto:' +
              t('emailaddr') +
              '?subject=' +
              subject +
              '&body=' +
              body.replace(/\n/g, '<br />')
          )
        }
      )

    // fetch('http://www.teonaperfume.ru/api/mail', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     password: 'PASSWD',
    //     email: 'kayjaylitee@gmail.com',
    //     from: 'order@teonaperfume.ru',
    //     subject: 'ORDER FROM TEONAPERFUME.RU',
    //     body: 'Приветствую,<br /><br />Новый заказ:<br />{{order}}<br /><br /> С лучшими пожеланиями,<br />TEONAPERFUME.RU<br />1546',
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     this.setState({ emailSent: true })
    //     console.log('SUCCESS!', data.status, data.text)
    //   })
    //   .catch((error) => {
    //     console.log('FAILED...', error)
    //     window.open(
    //       'mailto:' +
    //         t('emailaddr') +
    //         '?subject=' +
    //         subject +
    //         '&body=' +
    //         body.replace(/\n/g, '<br />')
    //     )
    //   })

    this.props.onShowCart(this.props.item)
  }

  render() {
    const { t } = this.props
    // eslint-disable-next-line
    this.state.finalOrder = this.props.orders.map(
      (el) => t('n_' + el.id) + '(' + el.quantity + 'шт. )'
    )

    if (this.state.emailSent) {
      return (
        <div className="full-item">
          <motion.div
            animate={{ x: [1000, 0] }}
            exit={{ x: [0, 1000] }}
            transition={{ ease: 'easeInOut' }}
          >
            <FaCircleXmark
              className="close-button"
              onClick={() => this.props.onShowFinal(this.props.item)}
            />
            <h2>{t('order_sent')}</h2>
            {t('callback_soon')}
            <div
              className="add-to-cart-full"
              onClick={() => this.props.onShowFinal(this.props.item)}
            >
              {t('close_word')}
            </div>
          </motion.div>
        </div>
      )
    } else
      return (
        <div className="full-item">
          <motion.div
            animate={{ x: [-1000, 0] }}
            exit={{ x: [0, -1000] }}
            transition={{ ease: 'easeInOut' }}
          >
            <h2>{t('final_order')}</h2>
            {t('want')}:&nbsp;
            {this.props.orders.map((el) => (
              <span key={el.id}>
                «{t('n_' + el.id)}» ({el.quantity} {t('pc')}),{' '}
              </span>
            ))}
            {t('final_price')}&nbsp;
            {new Intl.NumberFormat().format(
              this.props.orders.reduce(
                (acc, el) => acc + t('p_' + el.id) * parseInt(el.quantity),
                0
              )
            )}
            &nbsp;
            {t('rub')}.
            <br />
            <br />
            {t('send_contacts')}.
            <br />
            <br />
            <form onSubmit={this.handleSubmit}>
              <input name="order" defaultValue={this.state.finalOrder} hidden />
              <label>{t('fio')}*</label>
              <input
                name="name"
                required
                maxLength="80"
                placeholder={t('fio_example')}
                value={this.state.name}
                onChange={this.handleChange}
              />
              <label>{t('phone')}*</label>
              <input
                name="phone"
                type="phone"
                required
                maxLength="18"
                placeholder={t('phone_example')}
                value={this.state.phone}
                onChange={this.handleChange}
              />
              <label>{t('email')}*</label>
              <input
                name="email"
                type="email"
                required
                maxLength="30"
                placeholder={t('mail_example')}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <label>{t('comment')}</label>
              <textarea
                name="comment"
                maxLength="600"
                value={this.state.comment}
                onChange={this.handleChange}
              />
              <input
                type="submit"
                className="add-to-cart-full"
                value={t('send')}
              />
            </form>
            <FaCircleXmark
              className="close-button"
              onClick={() => this.props.onShowFinal(this.props.item)}
            />
          </motion.div>
        </div>
      )
  }
}

export default withTranslation()(ShowFinal)
