import React, { useState } from 'react'
import { FaBagShopping } from 'react-icons/fa6'
import { motion, AnimatePresence } from 'framer-motion'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import Slider from './Slider'

export default function Header(props) {
  const [showAbout, setShowAbout] = useState(false)

  const { t } = useTranslation()

  return (
    <header>
      <menu>
        <a href="#root">
          <motion.span
            animate={{ x: [-100, 0] }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
            className="logo"
          >
            {t('logo')}
          </motion.span>
        </a>
        <ul className="nav">
          <li onClick={() => props.onShowCart(props.item)}>
            &nbsp;
            <FaBagShopping
              className={`shop-cart-button ${props.showCart && 'active'}`}
            />
            &nbsp;
            {props.orders.length > 0 ? (
              <AnimatePresence>
                <motion.span
                  key={props.orders.length}
                  className="badge"
                  initial={{ opacity: 0 }}
                  animate={{ y: [200, 0], opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: 'linear', duration: 0.5 }}
                >
                  {props.orders.reduce((total, el) => total + el.quantity, 0)}
                </motion.span>
              </AnimatePresence>
            ) : (
              ''
            )}
          </li>
          <li>
            <a href="#about" onClick={() => setShowAbout(true)}>
              {t('about')}
            </a>
          </li>
          <li>
            <a href="#catalog">{t('catalog')}</a>
          </li>
          <li>
            <a href="#contacts">{t('contacts')}</a>
          </li>
          <li>
            <span className="nav" onClick={() => changeLanguage('ru')}>
              {t('rus')}
            </span>
            &nbsp;/&nbsp;
            <span className="nav" onClick={() => changeLanguage('en')}>
              {t('eng')}
            </span>
            &nbsp;/&nbsp;
            <span className="nav" onClick={() => changeLanguage('cn')}>
              {t('chn')}
            </span>
          </li>
        </ul>
      </menu>
      <Slider />
      <span id="about"></span>
      {/* <AnimatePresence>
        {showAbout && (
          <motion.div
            initial={{ y: -200, maxHeight: 0, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              maxHeight: 1000,
              transition: { duration: 0.5 },
            }}
            exit={{
              y: -200,
              maxHeight: 0,
              opacity: 0,
              transition: { duration: 0.25 },
            }}
            className="about"> */}
      <br />
      <div className="about">
        {<Trans i18nKey="about_t" components={{ 1: <br /> }} />}
      </div>
      {/* </motion.div> */}
      {/* )} */}
      {/* </AnimatePresence> */}
    </header>
  )
}
