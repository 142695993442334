import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Items from './components/Items'
import Categories from './components/Categories'
import ShowFullItem from './components/ShowFullItem'
import ShowFinal from './components/ShowFinal'
import Cart from './components/Cart'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AnimatePresence } from 'framer-motion'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      currentItems: [],
      items: [],
      fullItem: {},
      showFullItem: false,
      showFinal: false,
      showCart: false,
    }

    this.state.currentItems = this.state.items
    this.addToOrder = this.addToOrder.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this)
    this.chooseCategory = this.chooseCategory.bind(this)
    this.onShowItem = this.onShowItem.bind(this)
    this.onShowFinal = this.onShowFinal.bind(this)
    this.onShowCart = this.onShowCart.bind(this)
    this.increaseQuantity = this.increaseQuantity.bind(this)
    this.decreaseQuantity = this.decreaseQuantity.bind(this)

    const { t } = this.props

    // заполняем массив items в соответствии с порядком t('items_order')
    t('items_order')
      .split(', ')
      .map(Number)
      .forEach((id) => {
        if (i18next.exists('n_' + id)) {
          this.state.items.push({
            id: '' + id,
            category: t('c_' + id),
            quantity: 1,
          })
        }
      })
  }
  render() {
    return (
      <div className="wrapper">
        <Header
          onShowFinal={this.onShowFinal}
          onShowCart={this.onShowCart}
          showCart={this.showCart}
          onDelete={this.deleteOrder}
          orders={this.state.orders}
        />
        <AnimatePresence>
          {this.state.showCart && (
            <Cart
              onShowCart={this.onShowCart}
              onShowFinal={this.onShowFinal}
              onDelete={this.deleteOrder}
              increaseQuantity={this.increaseQuantity}
              decreaseQuantity={this.decreaseQuantity}
              orders={this.state.orders}
            />
          )}
        </AnimatePresence>
        <Categories chooseCategory={this.chooseCategory} />
        <Items
          onShowItem={this.onShowItem}
          items={this.state.currentItems}
          onAdd={this.addToOrder}
        />
        <AnimatePresence>
          {this.state.showFullItem && (
            <ShowFullItem
              item={this.state.fullItem}
              items={this.state.currentItems}
              onAdd={this.addToOrder}
              onShowItem={this.onShowItem}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {this.state.showFinal && (
            <ShowFinal
              onShowFinal={this.onShowFinal}
              onShowCart={this.onShowCart}
              showCart={this.showCart}
              orders={this.state.orders}
            />
          )}
        </AnimatePresence>
        <Footer />
      </div>
    )
  }

  onShowItem(item) {
    this.setState({ fullItem: item })
    this.setState({ showFullItem: !this.state.showFullItem })
  }

  onShowFinal() {
    this.setState({ showFinal: !this.state.showFinal })
  }

  onShowCart() {
    this.setState({ showCart: !this.state.showCart })
  }

  chooseCategory(category) {
    if (category === 'a') {
      this.setState({ currentItems: this.state.items })
      return
    }
    this.setState({
      currentItems: this.state.items.filter((el) => el.category === category),
    })
  }

  deleteOrder(id) {
    this.setState({ orders: this.state.orders.filter((el) => el.id !== id) })
  }

  addToOrder(item) {
    let isInArray = false
    this.state.orders.forEach((el) => {
      if (el.id === item.id) isInArray = true
    })

    if (!isInArray) this.setState({ orders: [...this.state.orders, item] })
  }

  increaseQuantity(id) {
    this.setState((prevState) => ({
      orders: prevState.orders.map((el) =>
        el.id === id ? { ...el, quantity: el.quantity + 1 } : el
      ),
    }))
  }

  decreaseQuantity(id) {
    this.setState((prevState) => {
      const orders = prevState.orders
        .map((el) =>
          el.id === id
            ? { ...el, quantity: el.quantity > 1 ? el.quantity - 1 : 0 }
            : el
        )
        .filter((el) => el.quantity !== 0)
      return { orders }
    })
  }
}
export default withTranslation()(App)
